import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/2024/12A/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/2024/12A/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2024/12A/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/2024/12A/4.jpg';
// import p5 from 'assests/Photos/ClassAssembly/2024/12A/5.jpg';
// import p6 from 'assests/Photos/ClassAssembly/2024/12A/6.jpg';
// import p7 from 'assests/Photos/ClassAssembly/2024/12A/7.jpg';


// import p7 from 'assests/Photos/ClassAssembly/6D/7.jpg';





import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly12A2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/class-assembly/12A/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/class-assembly/12A/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/class-assembly/12A/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/class-assembly/12A/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/class-assembly/12A/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/class-assembly/12A/6.webp`;
  const p7 = `${config.base_image_url}/home/events-activities/class-assembly/12A/7.webp`;
 
 



  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      

      {
        src: p7,
        source: p7,
        rows: 1.5,
        cols: 2,
      },

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Minimum Change, Maximum Impact
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 12A Date: 4 June 2024

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of grade 12A of National Public School Yeshwanthpur showcased their creativity and talent through a unique assembly presentation on the theme of decluttering titled ‘Minimum Change, Maximum Impact’. The presentation focused on a musical which highlighted ‘The Diderot Effect’, a social phenomenon where acquiring a new possession leads to a spiral of consumption, prompting the acquisition of additional items and thus adding to the clutter. This narrative was further elaborated through a classical dance based on a mythological story, ‘The Writing of the Mahabharata’ which was interpreted as a metaphor of letting go of attachments like material possessions or emotional baggage to make room for growth and new beginnings. The students also provided practical tips to periodically declutter our physical spaces through ‘Four Box Method’, declutter emotionally by being vigilant against negative emotions and how it helps a student to become a better learner. In short, one needs to understand how to curate, eliminate and focus on the things that matter the most in life.

 <br></br>
 The entire assembly was a testament to the hard work and creativity of the Grade 12A students who presented an abstract concept in a tangible way. The students effectively conveyed an important message about the dangers of materialism and the value of simplicity. This event not only showcased their artistic talents but also sparked meaningful discussions on mindful consumption and personal fulfillment.
        <br></br>
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Simplying what’s around you will help you to simplify what’s within”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly12A2024;